import React, { useRef, useEffect, useState } from 'react';
import styles from './HowItWorks.module.css'
import World from './assets/world.svg'
import Money from './assets/money.svg'
import People from './assets/people.svg'
import Web from './assets/web.svg'


const HowItWorks = () => {

    const graphicRef = useRef(null);
    const cardRefs = useRef([]);
    const lastCardRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Check if the screen width is less than a certain value (e.g., 768 pixels) to determine if it's a mobile device
        const checkIsMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Initial check
        checkIsMobile();

        // Listen for window resize events and update the isMobile state
        window.addEventListener('resize', checkIsMobile);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // If the graphic is intersecting with the viewport, add a class to start the animation
                        entry.target.classList.add(styles.animate);
                    }
                });
            },
            {
                threshold: 0.3, // Adjust the threshold as needed
            }
        );

        // Start observing the graphic element
        if (graphicRef.current) {
            observer.observe(graphicRef.current);
        }

        // Clean up the observer when the component unmounts
        return () => {
            if (graphicRef.current) {
                observer.unobserve(graphicRef.current);
            }
        };
    }, []);

    useEffect(() => {

        const observerCard = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry, index) => {
                    if (entry.isIntersecting) {
                        cardRefs.current[index].classList.add(styles.animateCard);
                    }
                });
            },
            {
                threshold: 0.4,
            }
        );

        cardRefs.current.forEach((cardRef) => {
            if (cardRef) {
                observerCard.observe(cardRef);
            }
        });

        return () => {
            cardRefs.current.forEach((cardRef) => {
                if (cardRef) {
                    observerCard.unobserve(cardRef);
                }
            });
        };
    }, [cardRefs.current]);


    useEffect(() => {
        const thresholdValue = isMobile ? 0.4 : 0.9;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add(styles.animateCard);
                    }
                });
            },
            {
                threshold: thresholdValue,
            }
        );

        if (lastCardRef.current) {
            observer.observe(lastCardRef.current);
        }

        return () => {
            if (lastCardRef.current) {
                observer.unobserve(lastCardRef.current);
            }
        };
    }, [isMobile]);

    const handleBookCall = () => {
        // Determine the destination based on the device type
        const destination = isMobile ? 'sms:' : 'mailto:';
        
        // Specify your email and phone number
        const email = 'profityai@gmail.com';
        const phoneNumber = '+15614494820';
        
        // Specify the subject for the email
        const emailSubject = 'Hi, I would like to book a call';
        // Specify the body for the SMS
        const smsBody = 'Hi, I would like to book a call';
      
        if (isMobile) {
          // Open the SMS app with the pre-filled body
          window.location.href = `${destination}${phoneNumber}&body=${encodeURIComponent(smsBody)}`;
        } else {
          // Open the email client with the pre-filled subject
          window.location.href = `${destination}${email}?subject=${encodeURIComponent(emailSubject)}`;
        }
      };

    return (
        <>
            <div className={styles.container} id='howitworksid' >
                <div ref={graphicRef} className={styles.animateContainer}>
                    <h2 className={styles.title}>How it Works</h2>
                    <p className={styles.belowTitle}>Our simple 3 step process.</p>
                </div>
                <div className={styles.cardContainer}>

                    <div className={styles.firstCard}>
                        <img src={World} alt="Discover" className={styles.imageCard} />
                        <h3 className={styles.cardTitle}>Step One: Discover</h3>
                        <p className={styles.cardDescription}>Continually uncover new ways to automate your business with AI with unlimited meetings</p>
                    </div>

                    <div ref={(ref) => (cardRefs.current[0] = ref)} className={styles.card}>
                        <img src={Money} alt="Discover" className={styles.imageCard} />
                        <h3 className={styles.cardTitle}>Step Two: Automate</h3>
                        <p className={styles.cardDescription}>Request as many automation setups as you wish throughout the month</p>
                    </div>

                    <div ref={lastCardRef} className={styles.card}>
                        <img src={People} alt="Discover" className={styles.imageCard} />
                        <h3 className={styles.cardTitle}>Step Three: Operate</h3>
                        <p className={styles.cardDescription}>We then deploy and manage AI-powered automation across your business</p>
                    </div>

                </div>
                <button className={styles.button64} onClick={handleBookCall} role="button"><span class="text">Book a Call</span></button>



            </div>
        </>
    )
}

export default HowItWorks
