// Modal.js
import React, { useEffect, useState } from 'react';
import styles from './Modal.module.css';
import Logo from './assets/logo.png';

const Modal = ({ onClose, isOpen }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const handleBodyOverflow = () => {
      document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    };

    handleBodyOverflow(); // Set initial state on mount

    // Cleanup function to revert body overflow on unmount
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const handleClose = () => {
    setIsVisible(false);
    // Optionally, you can add a delay here if you want the exit animation to complete before closing the modal
    setTimeout(() => {
      onClose();
    }, 500);
  };

  const handleOptionClick = () => {
    handleClose(); // Close the modal when an option is clicked
    // You can add additional logic here based on the clicked option
  };

  // Render null if isOpen is false
  if (!isOpen) {
    return null;
  }

  return (
    <div className={`${styles.modal} ${isVisible ? styles.open : ''}`}>
      <div className={styles.modalContent}>
        <div className={styles.logoAndXButton}>
          <img src={Logo} alt="ProfityAI" className={styles.logo} />
          <span className={styles.closeButton} onClick={handleClose}>
            &times;
          </span>
        </div>

        <div className={styles.options}>
          <a href="#howitworksid" onClick={handleOptionClick}>
            How it Works
          </a>
          <a href="#ourservicesid" onClick={handleOptionClick}>
            Our Services
          </a>
          <a href="#faqid" onClick={handleOptionClick}>
            FAQ
          </a>
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.separationLine}></div>
          <button className={styles.button64} role="button">
            <span className="text">Book a Call</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
