import React from 'react'
import styles from './Footer.module.css'
import Logo from './assets/logo.png'


const Footer = () => {
    return (
        <>
            <div className={styles.container}>

                <div className={styles.logoAndPhrase}>
                    <img src={Logo} alt="ProfityAI" className={styles.logo} />
                    <h2 className={styles.title}>ProfityAI is simply the AI Automation Agency <br /> of the Future</h2>
                </div>
                <div className={styles.social}>
                    <p className={styles.contactTitle}>Contacts:</p>
                    <a className={styles.marginspace} href='sms:+15614494820' target='blank' rel="noopener noreferrer" >Phone Number: <span className={styles.contactBold}> +15614494820 </span></a>
                    <a className={styles.marginspace} href='mailto:profityai@gmail.com@gmail.com' target='blank' rel="noopener noreferrer" >email: <span className={styles.contactBold}> profityai@gmail.com  </span></a>
                    <a href='https://www.instagram.com/profityai/' target='blank' rel="noopener noreferrer" >instagram: <span className={styles.contactBold}> @ProfityAI </span></a>


                </div>
            </div>


        </>
    )
}

export default Footer
