import React, { Suspense, useState, useRef, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Preload, useGLTF } from "@react-three/drei";
import styles from './Earth.module.css';
import CanvasLoader from "../Loader";
import MoreIcon from '../assets/moreIcon.svg'
import LessIcon from '../assets/lessIcon.svg'



const Earth = () => {
  const earth = useGLTF("./planet/scene.gltf");

  return (
    <primitive object={earth.scene} scale={5} position-y={0} rotation-y={0} />
  );
};

const EarthCanvas = () => {
  const questionsRef = useRef(null);
  const earthRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Check if the screen width is less than a certain value (e.g., 768 pixels) to determine if it's a mobile device
        const checkIsMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Initial check
        checkIsMobile();

        // Listen for window resize events and update the isMobile state
        window.addEventListener('resize', checkIsMobile);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    


  const earthScale = 5; // Set the desired scale

  const calculateCameraDistance = (scale) => {
    const fov = 60; // Increased field of view
    const aspect = window.innerWidth / window.innerHeight;

    // Calculate the distance based on the scale
    const distance = scale / Math.tan((fov * Math.PI) / 360);

    return distance;
  };

  const cameraDistance = calculateCameraDistance(earthScale);

  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardClick = (index) => {
    console.log('aaa')
    setSelectedCard(index === selectedCard ? null : index);

  };

  const faqData = [
    {
      question: "Who's the perfect fit for ProfityAI?",
      answer: "A brilliant question! ProfityAI caters to entrepreneurs and small to medium businesses aiming to bulletproof their operations through AI.",
    },
    {
      question:
        "Do I need to know what automations I need, or can you help identify the best ones?",
      answer: "If you've already envisioned the automations you want, that's great! But if not, no worries at all. Numerous businesses have vast untapped potential for optimization through our automation systems. Our team will help you discover these during our initial meeting call and assist you in prioritizing the ones that matter most.",
    },

    {
      question:
        "Why wouldn't I just hire a full-time developer?",
      answer: "Excellent question! Hiring a full-time senior developer can be costly, with an average annual salary exceeding $120,000, plus the extra costs of benefits. The hiring process itself can also be lengthy and challenging. ",
    },

    {
      question:
        "How long does it take to complete a development task?",
      answer: "Each automation request you add to the ideas board comes with an estimated implementation time. Depending on complexity, this can range from a couple of days to nearly half a month.",
    },

    {
      question:
        "What happens if I don't need development work for a certain month?",
      answer: "If you don't need any work for a specific month, you can pause your subscription and resume it when you need our services again. There are no penalties or additional fees for pausing your subscription.",
    },


    {
      question:
        "Is there a Refund Policy?",
      answer: "Due to the high quality nature of the work, there will be no refunds issued once a Task is active and has started.",
    },
  ];

  useEffect(() => {
    const thresholdValue = isMobile ? 0.1 : 0.5;

    const observerGraphic = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.animate);
          }
        });
      },
      {
        threshold: thresholdValue,
      }
    );

    if (questionsRef.current) {
      observerGraphic.observe(questionsRef.current);
    }

    return () => {
      if (questionsRef.current) {
        observerGraphic.unobserve(questionsRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const observerGraphic = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.animateEarth);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    if (earthRef.current) {
      observerGraphic.observe(earthRef.current);
    }

    return () => {
      if (earthRef.current) {
        observerGraphic.unobserve(earthRef.current);
      }
    };
  }, []);

  return (
    <>
      <div className={styles.container} id="faqid" >
        <div ref={earthRef} className={styles.containerEarth}>
          <Canvas
            className={styles.earth3d}
            shadows
            frameloop='demand'
            dpr={[1, 2]}
            gl={{ preserveDrawingBuffer: true }}
            camera={{
              fov: 60,
              near: 1, // Adjusted near plane
              far: cameraDistance * 10,
              position: [0, 0, cameraDistance],
            }}
          >
            <Suspense fallback={<CanvasLoader />}>
              <OrbitControls
                autoRotate
                enableZoom={false}
                maxPolarAngle={Math.PI / 2}
                minPolarAngle={Math.PI / 2}
                target={[0, 0, 0]}
              />
              <Earth />
              <Preload all />
            </Suspense>
          </Canvas>
        </div>
        <h2 className={styles.title}>Frequently Asked Questions</h2>

        <div ref={questionsRef} className={styles.cardContainer}>
          {faqData.map((data, index) => (
            <div
              key={index}
              className={`${styles.card} ${index === selectedCard ? styles.selected : ""}`}
              style={{ pointerEvents: 'auto' }}
              onClick={() => handleCardClick(index)}
            >
              <div className={styles.cardContent}>
                {index === selectedCard ? (
                  <img src={LessIcon} alt="" className={styles.moreIcon} />
                ) : (
                  <img src={MoreIcon} alt="" className={styles.moreIcon} />
                )}
                <p>{data.question}</p>
              </div>
              {index === selectedCard && (
                <div className={styles.answer}>
                  <p>{data.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>


    </>
  );
};

export default EarthCanvas;
