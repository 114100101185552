import React, { useRef, useEffect, useState } from 'react';
import styles from './Graphic.module.css';
import GraphicImage from './assets/graphic.svg';
import engine from './assets/engine.svg';
import velocity from './assets/velocity.svg';
import efficiency from './assets/efficiency.svg';
import quality from './assets/quality.svg';
import arrow from './assets/arrow.svg';


const Graphic = () => {
  const graphicRef = useRef(null);
  const cardRefs = useRef([]);

  useEffect(() => {
    const observerGraphic = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.animate);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    if (graphicRef.current) {
      observerGraphic.observe(graphicRef.current);
    }

    return () => {
      if (graphicRef.current) {
        observerGraphic.unobserve(graphicRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const observerCard = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            cardRefs.current[index].classList.add(styles.animate);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    cardRefs.current.forEach((cardRef) => {
      if (cardRef) {
        observerCard.observe(cardRef);
      }
    });

    return () => {
      cardRefs.current.forEach((cardRef) => {
        if (cardRef) {
          observerCard.unobserve(cardRef);
        }
      });
    };
  }, [cardRefs.current]);

  //mobile
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const cards = [
    {
      image: engine,
      text: 'Reduce Costs',
      description:
        'Eliminate dollars spent on repetitive tasks by assigning low ROI tasks to an AI assistant.',
    },

    {
      image: velocity,
      text: 'Increase Velocity',
      description:
        'Iterate through your cycles up to 5x faster by removing human friction.',
    },

    {
      image: efficiency,
      text: 'Boost Efficiency',
      description:
        'Provide your team with the tools they need to multiply their output.',
    },

    {
      image: quality,
      text: 'Quality',
      description:
        'Consistently produce quality output by eliminating human error and variability.',
    },

  ];

  const prevCard = () => {
    setCurrentCardIndex(
      (prevIndex) => (prevIndex - 1 + cards.length) % cards.length
    );

  };

  const nextCard = () => {
    setCurrentCardIndex((prevIndex) => (prevIndex + 1) % cards.length);
  };

  const totalCards = cards.length;

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>More Juice, Less Squeeze</h2>

      <p className={styles.subTitle}>Slash costs and boost profits by onboarding a team of AI assistants.</p>
      <img ref={graphicRef} src={GraphicImage} alt="Graphic" className={styles.graphic} />

      <div className={styles.containerMobile}>
      <div className={`${styles.cardMobile} ${styles.animate}`}>
          <img
            src={cards[currentCardIndex].image}
            alt="Coding"
            className={`${styles.cardImage} `}
          />
          <p className={styles.cardText}>{cards[currentCardIndex].text}</p>
          <p className={styles.smallText}>
            {cards[currentCardIndex].description}
          </p>
        </div>

        <div className={styles.containerButtons}>
          <button onClick={prevCard} className={styles.nextButton}>

            <img src={arrow} alt="prev" className={styles.arrow} />
          </button>

          <span className={styles.cardNumber}>
            <span className={styles.currentNumber}>{currentCardIndex + 1}</span>/{totalCards}
          </span>

          <button onClick={nextCard} className={styles.nextButton}>
            <img src={arrow} alt="prev" className={styles.arrowNext} />
          </button>
        </div>

      </div>

      <div className={styles.cardContainer}>
        <div ref={(ref) => (cardRefs.current[0] = ref)} className={styles.card}>
          <img src={engine} alt="Reduce Costs" className={`${styles.cardImageEngine} `} />
          <h3 className={styles.cardText}>Reduce Costs</h3>
          <p className={styles.smallText}>Save money on repetitive tasks by assigning low ROI tasks to an AI assistant.</p>
        </div>

        <div ref={(ref) => (cardRefs.current[1] = ref)} className={styles.card}>
          <img src={velocity} alt="Increase Velocity" className={`${styles.cardImage} `} />
          <h3 className={styles.cardText}>Increase Velocity</h3>
          <p className={styles.smallText}>Iterate through your cycles up to 5x faster by removing human friction.</p>
        </div>

        <div ref={(ref) => (cardRefs.current[2] = ref)} className={styles.card}>
          <img src={efficiency} alt="Boost Efficiency" className={`${styles.cardImage} `} />
          <h3 className={styles.cardText}>Boost Efficiency</h3>
          <p className={styles.smallText}>Provide your team with the tools they need to multiply their output.</p>
        </div>

        <div ref={(ref) => (cardRefs.current[3] = ref)} className={styles.card}>
          <img src={quality} alt="Quality" className={`${styles.cardImage} `} />
          <h3 className={styles.cardText}>Quality</h3>
          <p className={styles.smallText}>Consistently produce quality output by eliminating human error and variability</p>
        </div>
      </div>
    </div>
  );
};

export default Graphic;
