import React, { useRef, useEffect, useState } from 'react';
import styles from './CTA.module.css'

const CTA = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Check if the screen width is less than a certain value (e.g., 768 pixels) to determine if it's a mobile device
        const checkIsMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Initial check
        checkIsMobile();

        // Listen for window resize events and update the isMobile state
        window.addEventListener('resize', checkIsMobile);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    const handleBookCall = () => {
        // Determine the destination based on the device type
        const destination = isMobile ? 'sms:' : 'mailto:';

        // Specify your email and phone number
        const email = 'profityai@gmail.com';
        const phoneNumber = '+15614494820';

        // Specify the subject for the email
        const emailSubject = 'Hi, I would like to book a call';
        // Specify the body for the SMS
        const smsBody = 'Hi, I would like to book a call';

        if (isMobile) {
            // Open the SMS app with the pre-filled body
            window.location.href = `${destination}${phoneNumber}&body=${encodeURIComponent(smsBody)}`;
        } else {
            // Open the email client with the pre-filled subject
            window.location.href = `${destination}${email}?subject=${encodeURIComponent(emailSubject)}`;
        }
    };

    return (
        <>
            <div className={styles.container}>
                <div className={styles.boxContainer}>
                    <div className={styles.leftSide}>
                        <h2 className={styles.title}>Book a call!</h2>
                        <p className={styles.belowTitle}>Don't Get Left Behind: Join the 85% of Enterprises <br /> Implementing AI By 2026 - IDC</p>
                    </div>
                    <button className={styles.button64} onClick={handleBookCall} role="button"><span class="text">Book a Call</span></button>
                </div>

            </div>
        </>
    )
}

export default CTA
