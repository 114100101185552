import React, { useRef, useEffect } from 'react';
import styles from './WhatWeDoBest.module.css';
import Ai from './assets/ai.png';
import Crm from './assets/crm.png';
import ChatBot from './assets/customAiChatBot.png';
import Check from './assets/check.svg'

const WhatWeDoBest = () => {
  const graphicRef = useRef(null);
  const cardAIRef = useRef(null);
  const imgCrmRef = useRef(null);
  const cardCrmRef = useRef(null);
  const imgChatBotRef = useRef(null); // Add a ref for the imgChatBot element
  const cardChatBotRef = useRef(null); // Add a ref for the cardChatBot element

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.animate);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    if (graphicRef.current) {
      observer.observe(graphicRef.current);
    }

    return () => {
      if (graphicRef.current) {
        observer.unobserve(graphicRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.animate);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    if (cardAIRef.current) {
      observer.observe(cardAIRef.current);
    }

    return () => {
      if (cardAIRef.current) {
        observer.unobserve(cardAIRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.animate);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    if (imgCrmRef.current) {
      observer.observe(imgCrmRef.current);
    }

    return () => {
      if (imgCrmRef.current) {
        observer.unobserve(imgCrmRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.animate);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    if (cardCrmRef.current) {
      observer.observe(cardCrmRef.current);
    }

    return () => {
      if (cardCrmRef.current) {
        observer.unobserve(cardCrmRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.animate);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    if (imgChatBotRef.current) {
      observer.observe(imgChatBotRef.current);
    }

    return () => {
      if (imgChatBotRef.current) {
        observer.unobserve(imgChatBotRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.animate);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    if (cardChatBotRef.current) {
      observer.observe(cardChatBotRef.current);
    }

    return () => {
      if (cardChatBotRef.current) {
        observer.unobserve(cardChatBotRef.current);
      }
    };
  }, []);

  return (
    <>
      <div className={styles.container} id='ourservicesid'>
        <h2 className={styles.title}>What We Do Best</h2>
        <p className={styles.belowTitle}>
          Experience an exceptional start to automation with our rapid delivery. In addition to your initial automation, we go the extra mile by providing a tailored chatbot within just 72 hours.
        </p>

        <img ref={graphicRef} src={Ai} alt="AI" className={styles.optionsImageAI} />
        <div ref={cardAIRef} className={styles.cardAI}>
          <h2 className={styles.cardTitle}> Custom AI Solutions & Automations.</h2>
          <div className={styles.details}>
            <img src={Check} alt="Check" className={styles.check} />
            <p className={styles.belowCardTitle}>Harness the power of Artificial Intelligence to streamline daily tasks.  </p>
          </div>

          <div className={styles.details}>
            <img src={Check} alt="Check" className={styles.check} />
            <p className={styles.belowCardTitle}>transform your operations from Sales, Lead Generation, Customer Support, and beyond. </p>
          </div>

          <div className={styles.details}>
            <img src={Check} alt="Check" className={styles.check} />
            <p className={styles.belowCardTitle}>Our AI solutions easily integrate into the applications your business uses daily, ensuring flexibility and compatibility with your existing systems. </p>
          </div>

          <div className={styles.details}>
            <img src={Check} alt="Check" className={styles.check} />
            <p className={styles.belowCardTitle}>Paving the way for smarter, more productive workflows.  </p>
          </div>



        </div>

        <img ref={imgCrmRef} src={Crm} alt="CRM" className={styles.optionsImageCRM} />
        <div ref={cardCrmRef} className={styles.cardCRM}>
          <h2 className={styles.cardTitle}> Custom Business Solutions.</h2>
          <div className={styles.details}>
            <img src={Check} alt="Check" className={styles.check} />
            <p className={styles.belowCardTitle}>Revolutionize your business with custom solutions or by seamlessly integrating internal tools into a tailored, single-point dashboard.  </p>
          </div>

          <div className={styles.details}>
            <img src={Check} alt="Check" className={styles.check} />
            <p className={styles.belowCardTitle}>Build your own systems like CRMs and Business Management Tools, tailored specifically to your business.  </p>
          </div>

          <div className={styles.details}>
            <img src={Check} alt="Check" className={styles.check} />
            <p className={styles.belowCardTitle}>Supercharge these systems with our Artificial Intelligence solutions. </p>
          </div>


        </div>

        <img ref={imgChatBotRef} src={ChatBot} alt="Custom Chat Bot" className={styles.optionsImageChatBot} />
        <div ref={cardChatBotRef} className={styles.cardChatBot}>
          <h2 className={styles.cardTitle}> Custom AI Chatbots.</h2>
          <div className={styles.details}>
            <img src={Check} alt="Check" className={styles.check} />
            <p className={styles.belowCardTitle}>Build custom knowledge based AI chatbot systems that can be applied with a variety of use cases. </p>
          </div>
        </div>

        <div className={styles.line}></div>
      </div>
    </>
  );
};

export default WhatWeDoBest;
