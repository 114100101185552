import React, { useRef, useEffect, useState } from 'react';
import styles from './HeroTest.module.css';
import Engine from './assets/HeroEngine.webm';
import Logo from './assets/logo.png'
import Menu from './assets/menu.svg'
import Modal from './Modal';
import Spline from '@splinetool/react-spline';


const HeroTest = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const videoRef = useRef(null);
  const graphicRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if the screen width is less than a certain value (e.g., 768 pixels) to determine if it's a mobile device
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Initial check
    checkIsMobile();

    // Listen for window resize events and update the isMobile state
    window.addEventListener('resize', checkIsMobile);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // If the graphic is intersecting with the viewport, add a class to start the animation
            entry.target.classList.add(styles.animate);
          }
        });
      },
      {
        threshold: 0.5, // Adjust the threshold as needed
      }
    );

    // Start observing the graphic element
    if (graphicRef.current) {
      observer.observe(graphicRef.current);
    }

    // Clean up the observer when the component unmounts
    return () => {
      if (graphicRef.current) {
        observer.unobserve(graphicRef.current);
      }
    };
  }, []); // Run the effect once when the component mounts



  useEffect(() => {
    // Add the 'colorChange' class to the span element after 2 seconds
    const timeoutId = setTimeout(() => {
      const span = document.querySelector(`.${styles.title} span.${styles.colorChangeBefore}`);
      if (span) {
        span.classList.add(styles.colorChange);
      }
    }, 1000);

    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, []);


  const handleMenuClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleBookCall = () => {
    // Determine the destination based on the device type
    const destination = isMobile ? 'sms:' : 'mailto:';
    
    // Specify your email and phone number
    const email = 'profityai@gmail.com';
    const phoneNumber = '+15614494820';
    
    // Specify the subject for the email
    const emailSubject = 'Hi, I would like to book a call';
    // Specify the body for the SMS
    const smsBody = 'Hi, I would like to book a call';
  
    const isiOS = isMobile && /iPad|iPhone|iPod/.test(navigator.platform);

    if (isMobile) {
      // For mobile devices
      if (isiOS) {
        // For iOS, open the SMS app with the pre-filled body
        window.location.href = `${destination}${phoneNumber}&body=${encodeURIComponent(smsBody)}`;
      } else {
        // For Android or other mobile platforms, open the SMS app with the pre-filled body
        window.location.href = `${destination}${phoneNumber}?body=${encodeURIComponent(smsBody)}`;
      }
    } else {
      // For desktop, open the email client with the pre-filled subject
      window.location.href = `${destination}${email}?subject=${encodeURIComponent(emailSubject)}`;
    }
  };
  

  return (
    <>
      <div className={styles.container}>
        <div ref={graphicRef} className={styles.containerAnimation}>


          <div className={styles.hamburgerContainer}>
            <img src={Logo} alt="ProfityAI" className={styles.logo} />
            <img src={Menu} alt="Menu" className={styles.menu} onClick={handleMenuClick} />
          </div>

          <div className={styles.header}>
            <img src={Logo} alt="ProfityAI" className={styles.logo} />
            <div className={styles.headerOptions}>
              <a href="#howitworksid">How it Works</a>
              <a href='#ourservicesid'>Our Services</a>
              <a href="#faqid">FAQ</a>
            </div>
          </div>
          <h1 className={styles.title}>Boost Your <span className={styles.colorChangeBefore}> Profits  </span> with AI</h1>

          <button className={styles.button64} role="button" onClick={handleBookCall}><span class="text">Book a Call</span></button>

          <div ref={videoRef} className={styles.secondbg}>

          </div>
        </div>
      </div>
      <Modal onClose={handleCloseModal} isOpen={isModalOpen} />

    </>
  );
};

export default HeroTest;
