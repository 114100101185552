import Robot from './assets/robot.mp4'
import Graphic from './Graphic';
import HowItWorks from './HowItWorks';
import Web from './assets/web.svg'
import styles from './App.module.css'
import WhatWeDoBest from './WhatWeDoBest';
import CTA from './CTA';
import StarsCanvas from './canvas/Stars';
import Earth from './canvas/Earth';
import EarthCanvas from './canvas/Earth';
import HeroTest from './HeroTest';
import Footer from './Footer';



function App() {
  return (
    <>
      <HeroTest />
      <HowItWorks />
      <Graphic />
      <WhatWeDoBest />
      <span className={styles.displaycta}>
        <CTA />
      </span>
      <EarthCanvas />
      <StarsCanvas />
      <Footer />


    </>
  );
}

export default App;
